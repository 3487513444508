import React, { Component } from "react"
// import {ClientsTestimony, ReadyToGrow, InsideBanner} from "../Common";
import { InsideBanner } from "../Common"
class Terms extends Component {
  state = {
    content: "Privacy policy goes here",
  }
  render() {
    return (
      <div className="aboutWrapper">
        <InsideBanner title="Privacy Policy" />
        <div className="container">
          <h6>Privacy Policy</h6>
          <p className="privacyText"> {this.state.content}</p>
        </div>
      </div>
    )
  }
}
export default Terms
