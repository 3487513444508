/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FaBars } from 'react-icons/fa';

import './navbar.scss';
import { useEffect } from 'react';
import { useState } from 'react';

const Navbar = () => {
  const [state, setState] = useState(false);
  const [active, setActive] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [state]);
  const handleScroll = () => {
    if (window.pageYOffset > 140) {
      if (!state) {
        setState(true);
      }
    } else {
      if (state) {
        setState(false);
      }
    }
  };

  return (
    <>
      <nav className={`top-navbar  ${state && 'sticky'}`}>
        <div div="active-logo">
          <FaBars
            className="burger"
            size={30}
            onClick={() => setActive(!active)}
          />
          <Link to="/">
            <img src={Logo} alt="UNIVERSAL MIND MEDIA" />
          </Link>
        </div>
        <ul className={`${active && 'active'}`}>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/works">Our Works</Link>
          </li>
          <Dropdown overlay={serviceMenu}>
            <a
              href="#"
              className="ant-dropdown-link"
              style={{
                color: 'black',
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Services <DownOutlined />
            </a>
          </Dropdown>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <Button
            style={{
              backgroundColor: '#F58605',
              color: 'white',
            }}
          >
            Get A Quote
          </Button>
        </ul>
      </nav>
    </>
  );
};

// const Navbar = () => {
//   const [state, setState] = useState(false);
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//   }, [state]);
//   const handleScroll = () => {
//     if (window.pageYOffset > 140) {
//       if (!state) {
//         setState(true);
//       }
//     } else {
//       if (state) {
//         setState(false);
//       }
//     }
//   };
//   return (
//     <nav className={`top-navbar  ${state && 'sticky'}`}>
//       <a href="/" className="brand-logo-nav">
//         <img src={Logo} alt="UNIVERSAL MIND MEDIA" />
//       </a>
//       <Menu
//         theme="light"
//         mode="horizontal"
//         defaultSelectedKeys={['Home']}
//         overflowedIndicator={<MenuOutlined />}
//       >
//         <Menu.Item key="Home">
//           <Link to="/">Home</Link>
//         </Menu.Item>
//         <Menu.Item key={'Blog'}>
//           <Link to="/blog">Blog</Link>
//         </Menu.Item>
//         <Menu.Item key={'About'}>
//           <Link to="/about">About</Link>
//         </Menu.Item>
//         <Dropdown overlay={serviceMenu}>
//           <a
//             href="#"
//             className="ant-dropdown-link"
//             style={{
//               color: 'black',
//             }}
//             onClick={(e) => {
//               e.preventDefault();
//             }}
//           >
//             Services <DownOutlined />
//           </a>
//         </Dropdown>
//         <Menu.Item key={'Our Works'}>
//           <Link to="/works">Our Works</Link>
//         </Menu.Item>
//         <Menu.Item key={'Contact'}>
//           <Link to="/contact">Contact</Link>
//         </Menu.Item>
//         <Button
//           style={{
//             backgroundColor: '#F58605',
//             color: 'white',
//           }}
//         >
//           Get A Quote
//         </Button>
//       </Menu>
//     </nav>
//   );
// };

const serviceMenu = (
  <Menu
    style={{
      textTransform: 'uppercase',
    }}
  >
    <Menu.Item>
      <Link to="/services/2">Content and Video Marketing</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/services/3">Social Media and Influencer Marketing</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/services/4">
        Search Engine Optimization and Strategy Building
      </Link>
    </Menu.Item>
    <Menu.Item>
      {' '}
      <Link to="/services/1">website and application building</Link>
    </Menu.Item>
    <Menu.Item>
      {' '}
      <Link to="/services/5">ppc marketing and conversion rate</Link>
    </Menu.Item>
    <Menu.Item>
      {' '}
      <Link to="/services/6">online reputation management</Link>
    </Menu.Item>
  </Menu>
);

export default Navbar;
