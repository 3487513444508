import React, { Component } from "react";
import { Row, Col } from 'antd'

class WhatItIncludes extends Component {

  renderCards = (cards) => {
    return cards.map((card, index) => (
      <Col sm={12} md={12} lg={8} key={index}>
        <div>
          <div className='whatItIncludesIcon' >
            <img src={card.icon} alt={card.icon} />
          </div>
          <p>{card.title}</p>
        </div>
      </Col>
    ));
  };

  render() {
    const {data, title} = this.props
    return <div className='whatItIncludesWrapper'>
      <div className="container">
        {title && <h4>{this.props.title}</h4>}
        <Row>
          {this.renderCards(data)}
        </Row>
      </div>
    </div>;
  }
}

export default WhatItIncludes