import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Typography, Input, Button, message } from 'antd';
import FacebookOutlined from '@ant-design/icons/FacebookOutlined';
import InstagramOutlined from '@ant-design/icons/InstagramOutlined';
import LinkedinOutlined from '@ant-design/icons/LinkedinOutlined';
import './footer.scss';

const { Text } = Typography;

class Footer extends Component {
  formRef = React.createRef();

  onFinish = (values) => {
    this.formRef.current.resetFields();
    fetch(
      `https://6a12ggkn6k.execute-api.us-east-1.amazonaws.com/default/umm-admin-notifier`,
      { method: 'POST', body: JSON.stringify(values) }
    )
      .then((res) => {
        message.success('Your response has been submitted.');
      })
      .catch((err) => {
        console.log('Error', err);
      });
  };

  render() {
    return (
      <footer>
        {/* upper half of footer */}
        <Row className='upper-footer'>
          <Col className='footerList' xs={24} sm={12} md={6}>
            <h4>Company</h4>
            <p>
              <Link to='/'>Home</Link>
            </p>
            <p>
              <Link to='/about'>About</Link>
            </p>
            <p>
              <Link to='/contact'>Contact</Link>
            </p>
            <p>
              <Link to='/works'>Works</Link>
            </p>
            <p>
              <Link to='/'>Terms &amp; Conditions</Link>
            </p>
            <p>
              <Link to='/'>Privacy Policy</Link>
            </p>
          </Col>
          <Col className='footerList' xs={24} sm={12} md={6}>
            <h4>Quick Links</h4>
            <p>
              <Link to='/services/seo'>SEO</Link>
            </p>
            <p>
              <Link to='/services/ppc'>Paid &amp; Social</Link>
            </p>
            <p>
              <Link to='/services/analytics'>Web Analytics</Link>
            </p>
            <p>
              <Link to='/services/ecommerce'>Web Design &amp; Dev</Link>
            </p>
            <p>
              <Link to='/services/orm'>ORM</Link>
            </p>
          </Col>
          <Col className='footerList' xs={24} sm={12} md={6}>
            <h4>Subscribe Us</h4>

            <Form
              name='subscribe'
              ref={this.formRef}
              className='contact-form'
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
            >
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input your email ID!',
                  },
                ]}
              >
                <Input placeholder='Email Address' />
              </Form.Item>
              <Form.Item>
                <Button type='primary' danger htmlType='submit'>
                  SUBSCRIBE
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col className='footerList' xs={24} sm={12} md={6}>
            <h4>Contact Us</h4>
            <div className='footer-address'>
              <p>Universalmind Technologies Pvt Ltd</p>
              <p>No. 3581, First Floor, 4th Cross,</p>
              <p>13th G Main HAL 2ND Stage,</p>
              <p> Indiranagar, Bengaluru, Karnataka</p>
              <p>560008. India</p>
            </div>
          </Col>
        </Row>

        {/* bottom half of footer */}
        <Row justify='space-around' align='middle' className='lower-footer'>
          <Col className='copyright' xs={24} sm={24} md={15}>
            2020 © Copyright Reserved
            <span className='specialText'> UMM</span>
          </Col>
          <Col xs={8} sm={8} md={3} className='socialLinks'>
            <a
              href={'https://www.facebook.com/universalmindmedia/'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <FacebookOutlined title='Facebook' />
              <span>Facebook</span>
            </a>
          </Col>
          <Col xs={8} sm={8} md={3} className='socialLinks'>
            <a
              href={
                'https://instagram.com/universalmind.media?igshid=w09mlinjm3g8'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <InstagramOutlined title='Instagram' />
              <span>Instagram</span>
            </a>
          </Col>
          <Col xs={8} sm={8} md={3} className='socialLinks'>
            <a
              href={'https://www.linkedin.com/company/universal-mind-media'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <LinkedinOutlined title='Linkedin' />
              <span>Linkedin</span>
            </a>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
