import React, { Component } from 'react'
import { Row, Col } from 'antd'
import GetQuotesForm from './getQuotesForm'

class ReadyToGrow extends Component {
  
  render() {
    return (
      <Row id='getQouteForm' className="readyToGrow container" align="middle" justify="center">
        <Col xs={20} sm={20} md={20} lg={10} xl={10}>
          <h2>Ready to grow your business?</h2>
        </Col>
        <Col xs={16} sm={16} md={16} lg={10} xl={10} className="growGetQuotesForm">
          <GetQuotesForm  />
        </Col>
      </Row>
    )
  }
}

export default ReadyToGrow