/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, Row, Space } from 'antd';
import React from 'react';
import logo1 from '../../../assets/Home/logos/VVStudio.png';
import logo2 from '../../../assets/Home/logos/billion-smiles-02.png';
import logo3 from '../../../assets/Home/logos/nbyula_logo.jpg';
import { Link } from 'react-router-dom';

const CaseStudy = () => {
  return (
    <div className='CaseStudy'>
      <Row>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <h2>CASE STUDY</h2>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            marginTop: '3rem',
          }}
        >
          <Space wrap align='center'>
            <Card
              hoverable
              className='case-study-card'
              cover={
                <img alt='Case Study' src={logo2} className='small-logo' />
              }
            >
              <Link to='/casestudy/1'>Read More</Link>
            </Card>
            <Card
              hoverable
              className='case-study-card'
              cover={<img className='black-img' alt='Case Study' src={logo1} />}
            >
              <Link to='/casestudy/2'>Read More</Link>
            </Card>

            <Card
              hoverable
              className='case-study-card'
              cover={<img className='black-img' alt='Case Study' src={logo3} />}
            >
              <Link to='/nbyula'>Read More</Link>
            </Card>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CaseStudy;
