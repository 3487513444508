import React, { Component } from 'react';
import { Card, Carousel, Col, Row, Image } from 'antd';

export default class ClientsTestimony extends Component {
  state = {
    testimonies: [
      [
        {
          content: `"I would say that the team at UMM are artists that stand out in the way they research and
                implement marketing strategies. We ve been working with them for over 3 years and we would
                highly recommend them to businesses that want to stand out from the crowd."`,
          icon: '/logos/navigus.png',
          link: 'https://nbyula.com',
        },
        {
          content: `"UMM has drawn massive traffic to our host website through rigorous team effort and
                collaboration. They have an excellent marketing team that comes up with out-of-the-box
                strategies and creatives to build customer engagement and conversion."`,
          icon: '/logos/vv.png',
          link: 'https://vvstudio.in/',
        },
      ],
      [
        {
          content: `"UMM has drawn massive traffic to our host website through rigorous team effort and
                collaboration. They have an excellent marketing team that comes up with out-of-the-box
                strategies and creatives to build customer engagement and conversion."`,
          icon: '/logos/billionsmiles.png',
          link: 'https://billionsmiles.org/',
        },
        {
          content: `"UMM has been our digital marketing agency for the longest time and I truly appreciate the
                marketing team that goes out of their way to deliver their services. They know their stuff very
                well and are experts at lead generation and Facebook campaigns."`,
          icon: '/logos/organickle.png',
          link: 'https://organickle.com/',
        },
      ],
    ],
  };

  renderTestimonies = () => {
    return this.state.testimonies.map((duoTestimony, index) => (
      <Row key={index}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card>
            <Card.Meta
              // avatar={service.icon}
              title={<p>{duoTestimony[0].content}</p>}
              description={
                <a href={duoTestimony[0].link} target='_blank'>
                  <img
                    src={duoTestimony[0].icon}
                    alt={duoTestimony[0].icon}
                    width='150'
                    height='50'
                  />
                </a>
              }
            />
          </Card>
        </Col>
        {duoTestimony[1] && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card>
              <Card.Meta
                // avatar={service.icon}
                title={<p>{duoTestimony[1].content}</p>}
                description={
                  <a href={duoTestimony[1].link} target='_blank'>
                    <img
                      src={duoTestimony[1].icon}
                      alt={duoTestimony[1].icon}
                      width='150'
                      height='50'
                    />
                  </a>
                }
              />
            </Card>
          </Col>
        )}
      </Row>
    ));
  };
  render() {
    return (
      <div className='clientsTestimony'>
        <div className='clientsTestimonyHeader'>
          <h2>Check what our clients say about us</h2>
        </div>
        <Carousel autoplay className='clientsTestimonyWrapper' fade>
          {this.renderTestimonies()}
        </Carousel>
      </div>
    );
  }
}
