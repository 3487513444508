import React from 'react';
import { Row, Col } from 'antd';
import img1 from '../../../assets/Home/1.jpg';
import img2 from '../../../assets/Home/2.jpg';
import img3 from '../../../assets/Home/3.jpg';

const WhatAreYouLookingFor = () => {
  return (
    <>
      <div className="WhatAreYouLookingFor">
        <Row>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '5rem',
            }}
          >
            <h2>WHAT ARE YOU LOOKING FOR ?</h2>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <CardProduct img={img1} data={'<p>WEBSITE TRAFFIC</p>'} />
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <CardProduct img={img2} data={'<p>LEADS/SALES</p>'} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <CardProduct
              img={img3}
              data={'<p>DIGITAL CONTENT</p><p>FOR YOUR BUSINESS</p>'}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const CardProduct = (props) => (
  <div className="my-img-card">
    <div className="head-card">
      <img alt="WHAT ARE YOU LOOKING FOR" src={props.img} />
    </div>
    <div dangerouslySetInnerHTML={{ __html: props.data }} />
  </div>
);

export default WhatAreYouLookingFor;
