import React, { Component } from "react";
import LazyLoad from 'react-lazyload'
import { Link } from "react-router-dom"
import { Row, Col, Button, Image, Divider } from 'antd'
import whoWeAreImg from "../../../assets/home-sec-2.jpg"

export default class WhatWeAre extends Component {
  render() {
    return (
      <div className="whatWeAre">
        <Row align="middle">
          <Col xs={0} sm={0} md={0} lg={12} xl={12}>
            <LazyLoad>
              <Image src={whoWeAreImg} alt="whoweare" width="100%"/>
            </LazyLoad>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="whatWeAreContent">
            <h6>What we are</h6>
            <h2>All you need is an inbound marketing push!</h2>
            <div>
              <p>
                Universal Mind Media is an unconventional, versatile, and full-service digital marketing hub that gives your business the digital push it needs to connect with your audience. We believe in deeply understanding the behavior of your audience and approaching them with extensively researched strategies to bridge the gap between you and your audience and hence boost the growth of your business.
              </p>
              <Divider />
              <Button type="danger" className="customButtons" shape="round">
                <Link to="/about">know more</Link>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
