import React, { Component } from "react"
// import {ClientsTestimony, ReadyToGrow, InsideBanner} from "../Common";
import { InsideBanner } from "../Common"
class Terms extends Component {
  state = {
    content: "Terms and conditions goes here",
  }
  render() {
    return (
      <div className="aboutWrapper">
        <InsideBanner title="Terms and Conditions" />
        <div className="container">
          <h6>Our terms and conditions</h6>
          <p className="termsText"> {this.state.content}</p>
        </div>
      </div>
    )
  }
}
export default Terms
