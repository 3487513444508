import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useSpring, animated, config } from 'react-spring';
import umm from '../../../assets/Home/UMM.png';

const WhyChooseUs = () => {
  const props = useSpring({
    config: {
      ...config.molasses,
      duration: '1000',
    },
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
  });
  const [state, setState] = useState(0);
  React.useEffect(() => {
    let interval = setInterval(() => {
      setState(state + 1);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);
  const textArray = [
    <animated.p style={props}>
      <span>Total turnkey & complete online</span>
      <span> marketing solutions.</span>
    </animated.p>,
    <animated.p style={props}>
      <span>Highly efficient digital marketing agency services with</span>{' '}
      <span> an unbeaten track record.</span>
    </animated.p>,
    <animated.p style={props}>
      <span>Experts at Lowering your overall online marketing costs</span>
    </animated.p>,
    <animated.p style={props}>
      <span>Significantly increase your number </span>{' '}
      <span>of leads and business queries.</span>
    </animated.p>,
    <animated.p style={props}>
      <span>Totally scalable Internet marketing services.</span>
    </animated.p>,
    <animated.p style={props}>
      <span>Weekly and fortnightly reports.</span>
    </animated.p>,
    <animated.p style={props}>
      <span>
        Years of experience, heard-earned expertise and cutting-edge tools
      </span>
    </animated.p>,
  ];
  return (
    <div className='WhyChooseUs'>
      <Row>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h2>WHY CHOOSE US ?</h2>
        </Col>
      </Row>
      <Row
        style={{
          margin: '2rem 0',
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img src={umm} alt='UMM' />
        </Col>
      </Row>
      <Row
        style={{
          margin: '0.5rem 0',
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {textArray[state % textArray.length]}
        </Col>
      </Row>
    </div>
  );
};

export default WhyChooseUs;
