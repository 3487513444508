import React from "react"
import { Row, Col } from "antd"
import FacebookOutlined from "@ant-design/icons/FacebookOutlined"
import InstagramOutlined from "@ant-design/icons/InstagramOutlined"
import LinkedinOutlined from "@ant-design/icons/LinkedinOutlined"
import MailOutlined from "@ant-design/icons/MailOutlined"
import "./header.scss"

const Header = () => {
  return (
    <header className="top-nav-bar">
      <Row className="header-all" justify="space-around" align="middle">
        <Col className="socialMedia" xs={24} sm={24} md={14}>
          <a
            href={"https://www.facebook.com/universalmindmedia/"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <FacebookOutlined title="Facebook" />
          </a>
          <a
            href={"https://instagram.com/universalmind.media?igshid=w09mlinjm3g8"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <InstagramOutlined title="Instagram" />
          </a>
          <a
            href={
              "https://www.linkedin.com/company/universal-mind-media"
            }
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <LinkedinOutlined title="Linkedin" />
          </a>
        </Col>
        <Col xs={24} sm={12} md={5} className="contactLink">
          <a href="tel:+91-6364921983">
            <span>Call Us :</span> +91-6364921983
          </a>
        </Col>
        <Col xs={24} sm={12} md={5} className="contactLink">
          <MailOutlined />
          <a href="mailto:support@umm.media"> support@umm.media</a>
        </Col>
      </Row>
    </header>
  )
}

export default Header
