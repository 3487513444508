import React, { Component } from "react"
import DesktopOutlined from "@ant-design/icons/DesktopOutlined"
import DollarOutlined from "@ant-design/icons/DollarOutlined"
import LineChartOutlined from "@ant-design/icons/LineChartOutlined"
import SearchOutlined from "@ant-design/icons/SearchOutlined"
import WechatOutlined from "@ant-design/icons/WechatOutlined"
import { Row, Col, Card } from "antd"

export default class OurServices extends Component {
  state = {
    servicesOne: [
      {
        icon: <SearchOutlined />,
        title: "Search Engine Optimisation",
        description:
          "Around 3.5 billion users are active on various digital platforms every second of the day. Our goal is to put you on their radar in the most illustrious way possible. How do we do this? We use SEO techniques and tools such as keyword research, link building, generating  high-quality backlink, rich snippets, and much more.",
      },
      {
        icon: <DollarOutlined />,
        title: "Paid & Social Media Management",
        description:
          "Pay Per Click(PPC) marketing techniques and targeted social media campaigns aim to connect you with your potential audience. Reaching out to your audience is quite elementary. However, connecting with them at the right time and place is way more crucial and that's what we do!",
      },
      {
        icon: <LineChartOutlined />,
        title: `Web Analytics`,
        description: `Do you know how to spot a winning marketing campaign? Analytics! That's right, analyzing and tracking your audience is the biggest influencer of digital marketing success. We build data models that track the behavior of your audience and precisely predict their needs.`,
      },
    ],
    servicesTwo: [
      {
        icon: <DesktopOutlined />,
        title: "Web Design & Development/eCommerce",
        description: `
        Keeping functionality, usability, and visualization in mind, we deliver web design and development solutions based on your aesthetic.

        There's nothing more attractive than a brand that stands apart from its competitors. We help you create a brand that looks, feels, and radiates awesomeness.
        `,
      },
      {
        icon: <WechatOutlined />,
        title: "Online Reputation Management",
        description: `Monitoring and managing the brand online requires time and effort. Our agency focuses on interacting with customers and generating reviews online for your business. We have built a customer response strategy based on which we analyse customer behavior over the website and convert them as a positive review.`,
      },
    ],
  }

  renderServices = () => {
    return this.state.servicesOne.map((service, index) => (
      <Col key={index} xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card style={{ marginTop: "0.5rem" }}>
          <Card.Meta
            avatar={service.icon}
            title={<h4>{service.title}</h4>}
            description={<p>{service.description}</p>}
          />
        </Card>
      </Col>
    ))
  }

  renderServicesTwo = () => {
    return this.state.servicesTwo.map((service, index) => (
      <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card style={{ marginTop: "0.5rem" }}>
          <Card.Meta
            avatar={service.icon}
            title={<h4>{service.title}</h4>}
            description={<p>{service.description}</p>}
          />
        </Card>
      </Col>
    ))
  }

  render() {
    return (
      <div className="ourServices">
        <h2>Our Services</h2>
        <div className="ourServicesWrapper">
          <Row>{this.renderServices()}</Row>
        </div>
        <div className="ourServicesWrapper">
          <Row>{this.renderServicesTwo()}</Row>
        </div>
      </div>
    )
  }
}
