import React, { Component } from 'react';
import { Row, Col, Carousel } from 'antd';

class PlatformsWeWorkOn extends Component {
  render() {
    return (
      <div className="platformsWrap">
        <h2>Technology We Work On</h2>
        <Carousel autoplay>
          <Row>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/wooco.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/wordpress.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/shopify.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/drupal.png" alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/Android-Logo.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/django.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/node.jpg" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/react.png" alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/drupal.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/wooco.png" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/magento.jpg" alt="" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <img src="/logos/custom-dev.png" alt="" />
            </Col>
          </Row>
        </Carousel>
      </div>
    );
  }
}

export default PlatformsWeWorkOn;
