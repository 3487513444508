import React, { Component } from 'react';
import { Carousel } from 'antd';
import slide1 from '../../../assets/Coresel/1.png';
import slide2 from '../../../assets/Coresel/2.png';
import slide3 from '../../../assets/Coresel/3.png';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

const contentStyle = {
  height: '82vh',
  width: '100%',
};

class Banner extends Component {
  render() {
    return (
      <Carousel
        className="banner-home"
        autoplay
        autoplaySpeed="20"
        pauseOnHover={false}
      >
        <div>
          <Reveal effect="fadeIn">
            <div className="banner-text-overlay">
              <h1>
                Ranked amongst the top 10 Digital Marketing Agency in Bangalore
              </h1>
            </div>
          </Reveal>
          <img style={contentStyle} alt="Slider" src={slide1} />
        </div>
        <div>
          <Reveal effect="fadeIn">
            <div className="banner-text-overlay">
              <h1>Award winning creative and digital marketing services</h1>
            </div>
          </Reveal>
          <img style={contentStyle} alt="Slider" src={slide2} />
        </div>
        <div>
          <Reveal effect="fadeIn">
            <div className="banner-text-overlay">
              <h1>Award winning creative and digital marketing services</h1>
            </div>
          </Reveal>
          <img style={contentStyle} alt="Slider" src={slide3} />
        </div>
      </Carousel>
    );
  }
}
export default Banner;
