import { Col, Row } from 'antd';
import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const OurPerformance = () => {
  const countUpdata = [
    {
      end: 500,
      duration: 3,
      sign: '+',
      inc: true,
      text: 'SUCCESSFUL PROJECTS',
    },
    {
      end: 355,
      duration: 3,
      sign: '%',
      inc: true,
      text: 'RETURN ON ADS SPEND',
    },
    {
      end: 95,
      duration: 1.5,
      sign: '%',
      inc: true,
      text: 'CLIENT RETENTION',
    },
    {
      end: 165,
      duration: 1.7,
      sign: '%',
      inc: true,
      text: 'INCREASE IN REVENUE',
    },
  ];

  return (
    <div className="OurPerformance">
      <Row
        style={{
          marginBottom: '3rem',
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h2>OUR PERFORMANCE</h2>
        </Col>
      </Row>
      <Row className="my-band">
        {countUpdata.map((obj, i) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="my-band-data"
            key={i}
          >
            <span className="text-number">
              <CountUp end={obj.end} duration={obj.duration}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              {obj.sign}
            </span>
            <span className="text-desc">{obj.text}</span>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OurPerformance;
