import React, { Component } from "react"

class InsideBanner extends Component {
  render() {
    return (
      <div className="aboutBanner flexBox">
        <div>
          <p>{this.props.content}</p>
          <h1>{this.props.title}</h1>
        </div>
      </div>
    )
  }
}

export default InsideBanner
