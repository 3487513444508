import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import ScrollToTop from './utils/ScrollToTop';
import Home from '../src/components/Home';
import Header from './components/Base/header/header';
import Navbar from './components/Base/navbar/navbar';
import Footer from './components/Base/footer/footer';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
const About = lazy(() => import('../src/components/About'));
const Works = lazy(() => import('../src/components/Works'));
const Contact = lazy(() => import('../src/components/Contact'));
const Services = lazy(() => import('../src/components/Services'));
const WebsiteAndAppBuilding = lazy(() =>
  import(
    '../src/components/Services/WebsiteAndAppBuilding/WebsiteAndAppBuilding'
  )
);
const ContentViedioMarketing = lazy(() =>
  import(
    '../src/components/Services/ContentVideoMarketing/ContentVideoMarketing'
  )
);
const SocialMediaInfluencerMaerketing = lazy(() =>
  import(
    '../src/components/Services/SocialMediaInfluencerMaerketing/SocialMediaInfluencerMaerketing'
  )
);
const SeoStrategyBuilding = lazy(() =>
  import('../src/components/Services/SeoStrategyBuilding/SeoStrategyBuilding')
);
const PpcmarketingConversionrate = lazy(() =>
  import(
    '../src/components/Services/PpcmarketingConversionrate/PpcmarketingConversionrate'
  )
);
const OnlineReputationManagement = lazy(() =>
  import(
    '../src/components/Services/OnlineReputationManagement/OnlineReputationManagement'
  )
);
const Blog = lazy(() => import('../src/components/Blog/Blog.js'));
const SingleBlog = lazy(() => import('../src/components/Blog/SingleBlog'));
const CaseStudy = lazy(() => import('../src/components/CaseStudy/CaseStudy'));

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <ScrollToTop>
          {/* Header Starts from here */}
          <Header />
          {/* Navbar Starts from here */}
          <Navbar />
          {/* Main Content Starts from here */}
          <div className='App'>
            <Suspense
              fallback={
                <Spin
                  indicator={<LoadingOutlined />}
                  style={{ margin: '2rem', padding: '2rem' }}
                  size='large'
                />
              }
            >
              <Switch>
                <Route exact path='/about' component={About} />
                <Route exact path='/works' component={Works} />
                <Route exact path='/contact' component={Contact} />
                {/* <Route exact path="/services/:component" component={Services} /> */}
                <Route
                  exact
                  path='/services/1'
                  component={WebsiteAndAppBuilding}
                />
                <Route
                  exact
                  path='/services/2'
                  component={ContentViedioMarketing}
                />
                <Route
                  exact
                  path='/services/3'
                  component={SocialMediaInfluencerMaerketing}
                />{' '}
                <Route
                  exact
                  path='/services/4'
                  component={SeoStrategyBuilding}
                />
                <Route
                  exact
                  path='/services/5'
                  component={PpcmarketingConversionrate}
                />{' '}
                <Route
                  exact
                  path='/services/6'
                  component={OnlineReputationManagement}
                />
                {/* <Route exact path='/terms' component={Terms} /> */}
                {/* <Route exact path='/privacy' component={Privacy} /> */}
                <Route exact path='/' component={Home} />
                <Route exact path='/blog' component={Blog} />
                <Route exact path='/blog/:id' component={SingleBlog} />
                <Route exact path='/casestudy/:id' component={CaseStudy} />
                <Route exact path='/getQuotesForm' component={Home} />
                <Route exact path='/nbyula' component={CaseStudy} />
                {/* default routes */}
                <Route path='*' component={Home} />
              </Switch>
            </Suspense>
          </div>
          {/* Footer Starts from here */}
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
