import React, { Component } from 'react';
import { Banner, WhatWeAre, OurServices } from './components';
import { ClientsTestimony, ReadyToGrow } from '../Common';
import { Divider } from 'antd';
import WhatAreYouLookingFor from './components/WhatAreYouLookingFor';
import WhyChooseUs from './components/WhyChooseUs';
import OurDigitalMarketingSolutions from './components/OurDigitalMarketingSolutions';
import OurPerformance from './components/OurPerformance';
import CaseStudy from './components/CaseStudy';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

class Home extends Component {
  constructor() {
    super();
    window.history.replaceState(null, 'home', '/');
  }

  render() {
    return (
      <div className='home'>
        <Banner />
        <Reveal effect='fadeInUp'>
          <WhatAreYouLookingFor />
        </Reveal>
        {/* <WhatWeAre /> */}
        {/* <Divider /> */}
        <Reveal effect='fadeInUp'>
          <WhyChooseUs />
        </Reveal>
        <Reveal effect='fadeInUp'>
          <OurDigitalMarketingSolutions />
        </Reveal>
        {/* <OurServices /> */}
        <OurPerformance />
        <Reveal effect='fadeInUp'>
          <ClientsTestimony />
        </Reveal>
        <Reveal effect='fadeInUp'>
          <CaseStudy />
        </Reveal>
        <Reveal effect='fadeInUp'>
          <ReadyToGrow id='getQouteForm' />
        </Reveal>
      </div>
    );
  }
}

export default Home;
