import { Col, Row } from 'antd';
import React from 'react';
import img4 from '../../../assets/Home/4.jpg';
import img5 from '../../../assets/Home/5.jpg';
import img6 from '../../../assets/Home/6.jpg';
import img7 from '../../../assets/Home/7.jpg';
import img8 from '../../../assets/Home/8.jpg';
import img9 from '../../../assets/Home/9.jpg';
import img10 from '../../../assets/Home/10.jpg';
import img11 from '../../../assets/Home/11.jpg';
import img12 from '../../../assets/Home/12.jpg';
import img13 from '../../../assets/Home/13.jpg';

const OurDigitalMarketingSolutions = () => {
  return (
    <div className="OurDigitalMarketingSolutions">
      <Row
        style={{
          marginBottom: '5rem',
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h2>Our Digital Marketing Solutions</h2>
        </Col>
      </Row>
      <Row
        style={
          {
            // marginTop: '2rem',
          }
        }
      >
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img4} />
          <p>STRATEGY BUILDING</p>
        </Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img5} />
          <p>WEB DEVELOPMENT</p>
        </Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img6} />
          <p>APP DEVELOPMENT</p>
        </Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img7} />
          <p>SEARCH ENGINE OPTIMIZATION</p>
        </Col>
      </Row>
      <Row
        style={
          {
            // marginTop: '2rem',
          }
        }
      >
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="my-img-card">
          <img alt="OurDigitalMarketingSolutions" src={img8} />
          <p>PPC MARKETING</p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="my-img-card">
          <img alt="OurDigitalMarketingSolutions" src={img9} />
          <p>SOCIAL MEDIA</p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="my-img-card">
          <img alt="OurDigitalMarketingSolutions" src={img10} />
          <p>CONTENT/VIDEO MANAGEMENT</p>
        </Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img11} />
          <p>INFLUENCER MARKETING</p>
        </Col>
      </Row>
      <Row
        style={
          {
            // marginTop: '2rem',
          }
        }
      >
        <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img12} />
          <p>ONLINE REPRESENTATION MANAGEMENT</p>
        </Col>
        <Col className="my-img-card" xs={24} sm={24} md={6} lg={6} xl={6}>
          <img alt="OurDigitalMarketingSolutions" src={img13} />
          <p>CONVERSION RATE OPTIMIZATION</p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
      </Row>
    </div>
  );
};



export default OurDigitalMarketingSolutions;
