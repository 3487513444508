import React, { Component } from 'react';
import { Form, Input, Select, Button, message, notification } from 'antd';
const { TextArea } = Input;
class GetQuotesForm extends Component {
  formRef = React.createRef();
  state = { loading: false };

  openNotificationWithIcon = (type) => {
    notification[type]({
      message: 'Response Submitted',
      description:
        'Thank you for connecting with us, Our team will be connecting with you shortly.',
    });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    fetch(
      `https://6a12ggkn6k.execute-api.us-east-1.amazonaws.com/default/umm-admin-notifier`,
      { method: 'POST', body: JSON.stringify(values) }
    )
      .then((res) => {
        this.openNotificationWithIcon('success');
        message.success('Your response has been submitted.');
        this.formRef.current.resetFields();
      })
      .catch((err) => {
        console.log('Error', err);
      });
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="getQuotesForm">
        <h4>Get Quote now</h4>
        <Form
          name="get_quote"
          ref={this.formRef}
          className="contact-form"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                type: 'string',
                max: 50,
                message: 'Please input your Name!',
              },
            ]}
          >
            <Input placeholder="Your Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email ID!',
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="contact"
            rules={[
              {
                required: true,
                len: 10,
                message: 'Please input your Contact Number!',
              },
            ]}
          >
            <Input placeholder="Contact Number" type="number" />
          </Form.Item>
          <Form.Item
            name="websiteUrl"
            rules={[
              { required: false, type: 'url', message: 'enter a valid URL' },
            ]}
          >
            <Input placeholder="Website URL (optional)" />
          </Form.Item>
          <Form.Item name="service" rules={[{ required: true }]}>
            {}
            {}
            <Select
              id="serviceSelect"
              placeholder="Select Service"
              allowClear
              defaultValue={this.props.service}
            >
              <Select.Option value="seo">SEO</Select.Option>
              <Select.Option value="Social-media">
                Social Media & Marketing
              </Select.Option>
              <Select.Option value="analytics">Web Analytics</Select.Option>
              <Select.Option value="Web">
                Web Design & Development
              </Select.Option>
              <Select.Option value="orm">
                Online Reputation Management
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                type: 'string',
                max: 1000,
                message: 'Please input your message!',
              },
            ]}
          >
            <TextArea
              placeholder="Your Message"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="danger"
              htmlType="submit"
              disabled={this.state.loading}
              className="customButtons"
            >
              {this.state.loading ? 'Submitting' : 'Get Quote'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default GetQuotesForm;
